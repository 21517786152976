<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import "echarts-gl";
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  let maxData = 100;
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      data: {
        type: Array,
        default: ()=>{
          return [15, 20, 8]
        }
      },
      salvProName:{
        type: Array,
        default: ()=>{
          return ["展厅1", "展厅2", "展厅3"]
        }
      },
      units:{
        type:String
      },
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'data':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
        this.chart.setOption({
            grid: {
                left: "5%",
                top: 40,
                bottom: 10,
                right: '5%',
                containLabel: true
            },
            xAxis: {
                type: "category",
                data:this.salvProName,
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: true,
                    fontSize: 12,
                },
            },
            yAxis: [
                {
                    type: "value",
                    name:'(个)',
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: true
                    },
                },
            ],
            series: [
                {
                    name: "预警统计",
                    type: "bar",
                    barWidth: 20,
                    data: this.data,
                },
            ],
        })
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>